import './style.css';
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import Map from 'ol/Map';
import Projection from 'ol/proj/Projection';
import Static from 'ol/source/ImageStatic';
import ZoomSlider from 'ol/control/ZoomSlider';
import XYZ from 'ol/source/XYZ';
import View from 'ol/View';
import {getCenter} from 'ol/extent';
import {Attribution, defaults as defaultControls} from 'ol/control';

const extent = [0, 0, 3840, 2160];
//const extent = [0,-2160,3840,0];
const projection = new Projection({
  code: 'lienzo-image',
  units: 'pixels',
  extent: extent
});

const attribution = new Attribution({
  collapsible: false,
});

const map = new Map({
  target: 'map',
  layers: [
    //new ImageLayer({
    new TileLayer({
      /*source: new Static({
        attributions: '© <a href="https://www.theowlclub.net">TheOwlClub.net</a>',
        url: 'https://s3-toc-staff-prod.theowlclub.net/202209-discord-proyectodana/ProyectoDana_Lienzo1_20220905120400.png',
        projection: projection,
        imageExtent: extent,
      }),*/
      source: new XYZ({
        //url:"http://127.0.0.1:8083/{z}/{x}/{y}.png",
        url:"https://s3-toc-staff-prod.theowlclub.net/202209-discord-proyectodana/20220908-0100/{z}/{x}/{y}.png",
        projection: projection, // por no poner esta huevada perdi 3 horas
        attributions: '© Contribuidores, miembros y artistas de la comunidad de The Owl Club.'
      }),
    })
  ],
  target: 'map',
  view: new View({
    projection: projection,
    center: getCenter(extent),
    //center: [1920,-1080],
    zoom: 2,
    maxZoom: 4,
    minZoom: 2,
    //constrainOnlyCenter: true,
    extent: extent
  }),
  controls: defaultControls({attribution: false}).extend([new ZoomSlider()]).extend([attribution]),
});


function checkSize() {
  const small = map.getSize()[0] < 600;
  attribution.setCollapsible(small);
  attribution.setCollapsed(small);
}

window.addEventListener('resize', checkSize);
checkSize();